<template>
  <v-app
    id="login"
    class="primary"
  >
    <Notification />
    <v-main>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
            lg4
          >
            <ValidationObserver v-slot="{ passes }">
              <v-form @submit.prevent="passes(onLogin)">
                <v-card class="elevation-1 pa-3">
                  <v-card-text>
                    <div class="layout column align-center">
                      <img
                        src="@/assets/img/big_logo.png"
                        :alt="siteName"
                        width="300"
                        height="85"
                      >
                    </div>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="email"
                        name="email"
                        :error-messages="errors"
                        label="Email"
                        type="email"
                        prepend-icon="mdi-email"
                        required
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <v-text-field
                        v-model="password"
                        name="password"
                        :error-messages="errors"
                        :append-icon="passwordField ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="passwordField ? 'text' : 'password'"
                        prepend-icon="mdi-lock"
                        label="Password"
                        @click:append="passwordField = !passwordField"
                      />
                    </ValidationProvider>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      block
                      type="submit"
                      color="primary"
                      :loading="loading"
                    >
                      {{ $t('auth.login') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <div class="row">
                  <div class="offset-6 col-6 text-right">
                    <router-link
                      :to="{ name: 'dashboard.forgot_password' }"
                      class="text-light"
                    >
                      <small>Forgot password?</small>
                    </router-link>
                  </div>
                </div>
              </v-form>
            </ValidationObserver>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Notification from '@/components/Notification'
import { setPermissions, parseUserPermissions } from '@/plugins/ability'
import { login } from '@/utils'

export default {
  name: 'Login',
  components: {
    Notification
  },
  data: () => ({
    loading: false,
    siteName: process.env.VUE_APP_NAME,
    email: null,
    password: null,
    passwordField: false
  }),
  methods: {
    onLogin () {
      this.$http.post('/login', { email: this.email, password: this.password })
        .then((response) => {
          // verify that user has any role
          if (response.data.success === false || response.data.user.roles.length === 0) {
            this.$router.push({ name: 'denied' })
            return
          }
          login(response.data)
          // define user permissions
          setPermissions(parseUserPermissions(response.data.user))
          this.$router.push({ name: 'dashboard.home' })
        }).catch(error => {
          this.loading = false
          this.displayError(error)
        })
    }
  }
}
</script>

<style lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
